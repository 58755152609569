@import "settings";
@import "mixins";

:root {
    --black: #{$black};
    --color1: #{$color1};
    --color2: #{$color2};
    --color3: #{$color3};

    --color-red: #{$color-red};

    --gray: #{$gray};
    --light-gray: #{$light-gray};
    --medium-gray: #{$medium-gray};

    --white: #{$white};
    --medium-black: #{$medium-black};
    --background-color: #{$background-color};

    --body-font-family: #{$body-font-family};
    --font-awesome: #{$font-awesome};
}
::placeholder {
    color: $gray;
}
::-webkit-scrollbar-track {
    background-color: $light-gray;
}
::-webkit-scrollbar-thumb {
    background-color: $gray;
}
::-webkit-scrollbar {
    height: $scrollbar-height;
    width: $scrollbar-with;
}

/** */
html {
    font-family: sans-serif;
    font-variant-ligatures: no-common-ligatures;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
}
body {
    background: $body-background;
    font-size: $body-font-size;
    font-weight: 400;
    scroll-behavior: smooth;
}

input, textarea, main {
    &:focus {
        outline: none;
    }
}
.uppercase {
    text-transform: uppercase;
}
.o-hidden {
    overflow: hidden;
}
.relative, .p-relative {
    position: relative !important;
}
.absolute, .p-absolute {
    position: absolute !important;
}
.block, .d-block {
    display: block !important;
}
.inline-block, .d-inline-block {
    display: inline-block !important;
}
.flex, .d-flex {
    display: flex !important;
}
.inline-flex, .d-inline-flex {
    display: inline-flex !important;
}
.inline, .d-inline {
    display: inline !important;
}
.grid, .d-grid {
    display: grid !important;
}
.inline-grid, .d-inline-grid {
    display: inline-grid !important;
}

.flex-row {
    flex-direction: row;
}
.flex-column {
    flex-direction: column;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.h-auto {
    height: auto;
}

.space-between {
    justify-content: space-between;
}

* > p:last-child {
    margin-bottom: 0;
}
a {
    display: inline-block;
}
img {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    &[alt] {
        font-size: 0;
    }
    &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    &.alignleft {
        display: block;
        float: left;
        margin-right: 1rem;
    }
    &.alignright {
        display: block;
        float: right;
        margin-left: 1rem;
    }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 600;
    a {
        display: block;
    }
    p {
        color: currentColor;
        line-height: inherit;
        margin-bottom: 0;
    }
}
.screen-reader-text, .submenu-toggle-text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -10000em;
    width: 1px;
}
.skip-link {
    height: 0 !important;
    overflow: hidden;
    position: absolute;
}
button[type="submit"],
button[type="reset"],
button[type="button"] {
    cursor: pointer;
}
.draggable {
    touch-action: none;
    user-select: none;
}
.font-0 {
    font-size: 0 !important;
}

/** */
figure.wp-caption, figure.caption {
    img {
        display: inline-block;
    }
    .wp-caption-text, .caption-text {
        padding-top: 10px;
        color: $medium-black;
    }
    &.aligncenter {
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

/** */
.vertical {
    &.nested {
        &.menu {
            display: none;
        }
    }
}
.tabs-panel {
    display: none;
    padding: 0;
}
[data-tab-content] {
    display: none;
}
.hide, .hidden {
    display: none !important;
}

/** */
[data-ico-fa]::before {
    content: attr(data-ico-fa);
    font-family: $font-awesome;
}
[data-glyph-fa]::before {
    content: attr(data-glyph-fa);
    font-family: $font-awesome;
}
[data-glyph]::before {
    content: attr(data-glyph);
    font-family: $font-awesome;
}
[data-glyph-after]::after {
    content: attr(data-glyph-after);
    font-family: $font-awesome;
}

/** */
.clearfix {
    @include clearfix;
}
.after-overlay, .custom-logo-link {
    @include after-overlay;
}
.overlay {
    position: relative;
    .link-overlay {
        @include absolute-full;
        background: transparent;
    }
}
.underline {
    @include underline;
}
.mx-auto, .ml-auto {
    margin-left: auto;
}
.mx-auto, .mr-auto {
    margin-right: auto;
}
.white {
    color: $white;
}
.color1 {
    color: $color1;
}
.color2 {
    color: $color2;
}
.color3 {
    color: $color3;
}
.color-red {
    color: $color-red;
}
.heading-color {
    color: $heading-color;
}
.light, .f-light {
    font-weight: 300 !important;
}
.regular, .f-regular {
    font-weight: 400 !important;
}
.medium, .f-medium {
    font-weight: 500 !important;
}
.semi-bold, .f-semi-bold {
    font-weight: 600 !important;
}
.bold, .f-bold {
    font-weight: 700 !important;
}

.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-25 { margin-top: 25px !important; }
.mt-30 { margin-top: 30px !important; }

.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-25 { margin-bottom: 25px !important; }
.mb-30 { margin-bottom: 30px !important; }

.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-25 { padding-top: 25px !important; }
.pt-30 { padding-top: 30px !important; }

.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-25 { padding-bottom: 25px !important; }
.pb-30 { padding-bottom: 30px !important; }

.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }

.grid-container {
    @include not-xxdesktop {
        max-width: $global-width-medium;
    }
    &.width-extra {
        @include xxdesktop {
            max-width: $global-width-extra;
        }
    }
}
.extra-container {
    @include xxdesktop {
        max-width: $global-width-extra;
    }
}

.gutter-container {
    @include gutter-container();
}

.grid-x, .gutter-container {
    &.small-gutter {
        @include gutter-container(20, 0);
    }
    &.gutter-5 {
        @include gutter-container(5, 0);
    }
    &.gutter-10 {
        @include gutter-container(10, 0);
    }
    &.gutter-15 {
        @include gutter-container(15, 0);
    }
    &.gutter-20, &.small-gutter {
        @include gutter-container(20, 0);
    }
    &.m-gutter-10 {
        @include mobile {
            @include gutter-container(10, 0);
        }
    }
    &.m-gutter-15 {
        @include mobile {
            @include gutter-container(15, 0);
        }
    }
}

.grid-x {
    &.gap-5 {
        @include grid-gap(5, 0);
    }
    &.gap-10 {
        @include grid-gap(10, 0);
    }
    &.gap-15 {
        @include grid-gap(15, 0);
    }
    &.gap-20 {
        @include grid-gap(20, 0);
    }
    &.m-gap-15 {
        @include mobile {
            @include grid-gap(15, 0);
        }
    }
}

/** */
.button-arrow {
    @include button-arrow;
}

.effect1 {
    @include _effect1;
}
.effect2 {
    @include _effect2;
}
.effect3 {
    @include _effect3;
}

/** */
.cover {
    display: block;
    overflow: hidden;
    position: relative;
    .link-cover, .cover-trigger {
        @include absolute-full;
        background: transparent;
    }
}
.res {
    @include responsive-wrapper;
    &.auto {
        & > img {
            @include responsive-image-auto;
        }
    }
    &.scale {
        @include responsive-scale;
    }
    &.opacity {
        &:hover {
            opacity: 0.9;
        }
    }
}
.ratio, .ratio-1v1 {
    @include responsive-ratio(1, 1);
}
.ratio-3v2 {
    @include responsive-ratio(3, 2);
}
.ratio-2v3 {
    @include responsive-ratio(2, 3);
}
.ratio-4v3 {
    @include responsive-ratio(4, 3);
}
.ratio-3v4 {
    @include responsive-ratio(3, 4);
}
.ratio-16v9 {
    @include responsive-ratio(16, 9);
}
.ratio-9v16 {
    @include responsive-ratio(9, 16);
}
.ratio-21v9 {
    @include responsive-ratio(21, 9);
}

/** */
.ratio-m-3v2 {
    @include mobile {
        @include responsive-ratio(3, 2);
    }
}

/** */
@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
@keyframes gradient-animation {
    0% {
        background-position: 0 13%;
    }
    50% {
        background-position: 100% 88%;
    }
    100% {
        background-position: 0 13%;
    }
}
@keyframes image-animation {
    1% {
        opacity: 0;
        animation-timing-function: ease-in;
    }
    4% {
        opacity: 0.1;
        transform: scale(1.05);
        animation-timing-function: ease-out;
    }
    22% {
        opacity: 0.9;
        transform: scale(1.15) rotate(-2deg)
    }
    44% {
        opacity: 1;
        transform: scale(1.1) rotate(1deg);
    }
    96% {
        opacity: 0.2;
        transform: scale(1.05);
        animation-timing-function: ease-in;
    }
    99% {
        opacity: 0.1;
        animation-timing-function: ease-in;
    }
    100% {opacity: 0;}
}
@keyframes image-animation2 {
    1% {
        animation-timing-function: ease-in;
    }
    4% {
        transform: scale(1.05);
        animation-timing-function: ease-out;
    }
    22% {
        transform: scale(1.15) rotate(-2deg)
    }
    44% {
        transform: scale(1.1) rotate(1deg);
    }
    96% {
        transform: scale(1.05);
        animation-timing-function: ease-in;
    }
    99% {
        animation-timing-function: ease-in;
    }
    100% {
        transform: scale(1.001);
        animation-timing-function: ease-out;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes ng-circle-anim {
    0% {
        transform: rotate(0) scale(0.5) skew(1deg);
        opacity: 0.1;
    }
    30% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.4;
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.1;
    }
}
@keyframes ng-circle-fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.2;
    }
    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
}
@keyframes ng-circle-icon-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
    }
}

/** */
/** Elementor */
/** */
.elementor-section.elementor-section-boxed {
    & > .elementor-container {
        &.elementor-column-gap-default {
            max-width: rem-calc(1190);
            @include not-xxdesktop {
                max-width: rem-calc(1130);
            }
        }
    }
}
.section, .elementor-element, .sidebar {
    &._h1 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h1);
        }
    }
    &._h2 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h2);
        }
    }
    &._h3 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h3);
        }
    }
    &._h4 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h4);
        }
    }
    &._h5 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h5);
        }
    }
    &._h6 {
        margin-bottom: 0;
        .heading-title, .sidebar-title {
            @include font-size-heading(h6);
        }
    }
}

/** */
/* @todo pagination */
.pagination {
    gap: 8px;
    text-align: center;
    border: 0;
    padding: 5px 0;
    margin: 30px 0;
    @include flex-center;
    .current {
        font-weight: 500;
        color: $white;
        @include linear-gradient;
        border: none;
        border-radius: 50%;
        @include box(40px);
        @include flex-center;
        padding: 0;
        font-size: 16px;
    }
    li {
        &.current {
            display: inline-flex;
        }
        /*&:not(:last-child) {
            margin-right: 8px;
        }*/
    }
    a, button {
        font-weight: 500;
        color: $black;
        border: 1px solid $light-gray;
        border-radius: 50%;
        @include box(40px);
        padding: 0;
        @include flex-center;
        font-size: 16px;
        position: relative;
        &:hover {
            @include linear-gradient;
            color: $white;
            border: none;
            svg, i {
                fill: $white;
                color: $white;
            }
        }
    }
    a {
        /*display: inline-block;*/
        i {
            font-weight: 400;
            font-size: 15px;
            &:hover {
                color: $white;
            }
        }
        svg {
            fill: $black;
            width: 9px;
            position: absolute;
            &:hover {
                fill: $white;
            }
        }
    }
}

/** */
/* @todo .hotline-mobile*/
.hotline-mobile {
    position: fixed;
    background-color: transparent;
    cursor: pointer;
    z-index: 99999;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: visibility 0.5s;
    bottom: 4%;
    left: 4%;
    @include box(90px);
    opacity: 1;
    animation: fadeIn ease-in-out 8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @include mobile {
        @include box(90px);
    }
    .hl-circle {
        @include box(90px);
        background-color: transparent;
        border-radius: 100%;
        border: 2px solid $color-red;
        animation: ng-circle-anim 1.2s infinite ease-in-out;
        transition: all 0.5s;
        transform-origin: 50% 50%;
        backface-visibility: hidden;
        @include mobile {
            @include box(90px);
        }
    }
    .hl-circle-fill {
        @include box(56px);
        top: 17px;
        left: 17px;
        position: absolute;
        border-radius: 100%;
        border: 2px solid transparent;
        animation: ng-circle-fill-anim 2.3s infinite ease-in-out;
        transition: all 0.5s;
        transform-origin: 50% 50%;
        @include linear-gradient(90deg, $color-red);
        backface-visibility: hidden;
        @include mobile {
            @include box(56px);
            top: 17px;
            left: 17px;
        }
    }
    .hl-circle-icon {
        @include box(46px);
        animation: ng-circle-icon-anim 1s infinite ease-in-out;
        transform-origin: 50% 50%;
        @include linear-gradient(90deg, $color-red);
        position: absolute;
        top: 22px;
        left: 22px;
        border-radius: 100%;
        @include flex-center;
        backface-visibility: hidden;
        @include mobile {
            @include box(46px);
            top: 22px;
            left: 22px;
        }
        i, svg {
            color: $white;
            font-size: 24px;
            fill: $white;
            @include box(24px);
            @include mobile {
                font-size: 24px;
                @include box(24px);
            }
        }
        &::before {
            font-weight: 900;
            color: #fff;
            font-size: 22px;
            opacity: 0.9;
        }
    }
    .has-tip {
        cursor: default;
        border-bottom: none;
        font-weight: inherit;
    }
}

/** */
/* @todo .upseo-list*/
.upseo-list {
    list-style: none;
    margin: 0 1% 30px 1%;
    color: $black;
    li {
        border-bottom: 1px solid rgb(230 230 230 / 41%);
        padding-bottom: 7px;
        padding-top: 7px;
        display: flex;
        align-items: baseline;
        &:last-child {
            border-bottom: none;
        }
        &::before {
            content: "";
            font-weight: 700;
            display: block;
            margin-right: 10px;
            @include box(16px);
            background: url(../img/svg/bxs-tag-alt.svg);
            opacity: 0.8;
            background-size: 16px;
            position: relative;
            top: 2px;
        }
        .thumb {
            display: none;
        }
        .post-title {
            color: currentColor;
            font-size: 15px;
            &:hover {
                color: $color2;
            }
        }
        .post-date {
            display: none;
        }
    }
}

/** */
/* @todo .toTop*/
.toTop {
    @include box(32px);
    font-size: 17px;
    position: fixed;
    right: 15px;
    bottom: 60px;
    z-index: 9998;
    transition: opacity 0.3s ease-in-out;
    line-height: 32px;
    @include flex-center;
    background-color: rgb(255 255 255 / 80%);
    backdrop-filter: blur(8px);
    &::before {
        font-weight: 300;
        @include box(30px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
    }
    &::after {
        content: "";
        @include absolute-full;
        border: 1px solid #eee;
        outline: 1px solid $gray;
        background-color: transparentize($background-color, 0.5);
    }
    & > #toTopHover {
        display: none;
    }
    &:hover, &:focus {
        color: $color1;
        &::after {
            outline: 1px solid $color1;
            background-color: transparentize($light-gray, 0.5);
        }
    }
}

/** */
/* @todo .menu-lines*/
.menu-lines {
    background: none;
    border: none;
    @include box(28px, 24px);
    color: $color1;
    cursor: pointer;
    display: block;
    outline: none;
    padding: 0;
    position: relative;
    transition: color 0.3s ease;
    .menu-txt {
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
        display: none;
    }
    .line {
        background-color: currentColor;
        border-radius: 0;
        height: 2px;
        left: 0;
        position: absolute;
        transition: width 0.3s ease;
        width: 100%;
    }
    .line-1 {
        top: 0;
        width: 28px;
    }
    .line-2 {
        top: calc(50% - 1px);
        width: 26px;
    }
    .line-3 {
        bottom: 0;
        width: 24px;
    }
    &:hover, &:focus {
        background: none;
        outline: none;
    }
    &:hover {
        .line {
            width: 28px;
        }
    }
}

/** */
/* @todo .inline-share*/
.inline-share {
    align-items: baseline;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-flow: row nowrap;
    font-size: 12px;
    margin-top: 30px;
    padding: 15px 15px 17px 15px;
    .title {
        align-items: center;
        display: flex;
        font-size: inherit;
        font-weight: 500;
        margin-right: 15px;
        position: relative;
        top: 3px;
        &::before {
            height: auto;
            margin-right: 10px;
            font-size: 15px;
            font-weight: 300;
        }
        span {}
    }
    .inline-share-groups {
        align-items: flex-end;
        display: flex;
        & > * {
            margin-left: 10px;
        }
        .zalo-follow-only-button {
            max-height: 20px;
        }
    }
}

/** */
/* @todo .share-icon*/
.share-icon {
    background: $background-color;
    border: 1px solid $gray;
    @include box(36px);
    border-radius: 50%;
    display: block;
    padding: 0;
    position: relative;
    &::before {
        background-repeat: no-repeat;
        width: 50%;
        height: 50%;
        background-size: contain;
        content: "";
        @include centering-elements;
        /*filter: brightness(0) invert(0.9);*/
    }
    &.icon-facebook {
        &::before {
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{$medium-black}" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>');
        }
    }
    &.icon-twitter {
        &::before {
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$medium-black}" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>');
        }
    }
    &.icon-telegram {
        &::before {
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$medium-black}" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"/></svg>');
        }
    }
    &.icon-send-email, &.icon-mail {
        &::before {
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$medium-black}" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/></svg>');
        }
    }
    &.icon-web-share, &.icon-share {
        &::before {
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$medium-black}" d="M352 320c-25.6 0-48.9 10-66.1 26.4l-98.3-61.5c5.9-18.8 5.9-39.1 0-57.8l98.3-61.5C303.1 182 326.4 192 352 192c53 0 96-43 96-96S405 0 352 0s-96 43-96 96c0 9.8 1.5 19.6 4.4 28.9l-98.3 61.5C144.9 170 121.6 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.6 0 48.9-10 66.1-26.4l98.3 61.5c-2.9 9.4-4.4 19.1-4.4 28.9 0 53 43 96 96 96s96-43 96-96-43-96-96-96zm0-272c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM96 304c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm256 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"/></svg>');
        }
    }
}

/** */
/* @todo .sharing_toolbox*/
.sharing_toolbox {
    .share-title {
        align-items: center;
        bottom: 35px;
        color: $medium-black;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        transform: rotate(-90deg);
        white-space: nowrap;
        &::before {
            background: #ccc;
            content: "";
            height: 1px;
            margin-right: 3px;
            width: 10px;
        }
    }
    .social-share {
        & > .share-label {
            display: none;
        }
        ul.share-actions {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            & > li.share-action {
                display: flex;
                margin-bottom: 15px;
                & > button.share-button {
                    cursor: pointer;
                    opacity: 0.6;
                    transition: 0.3s;
                    &:hover {
                        opacity: 0.9;
                    }
                    & > .share-icon {
                        &.icon-facebook {
                            &::before {
                                left: 60%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/** */
/* @todo off-canvas*/
.position-top {
    transform: translateY(-100%);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.position-bottom {
    transform: translateY(100%);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.js-off-canvas-overlay {
    background-color: transparentize($black, 0.6);
    @include fixed-full;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
    visibility: hidden;
    z-index: 0;
    &.is-visible {
        z-index: 9998;
    }
}
.off-canvas {
    background-color: rgb(25 25 25 / 90%);
    box-shadow: -3px 0 3px transparentize($medium-black, 0.9);
    padding: 0;
    text-align: left;
    transition: transform 0.6s ease-in-out;
    backdrop-filter: blur(15px);
    &.is-transition-overlap {
        z-index: 1000001;
        &.is-open {
            box-shadow: -3px 0 3px transparentize($medium-black, 0.8);
        }
    }
    .menu-lines {
        color: $white;
        position: absolute;
        top: 20px;
        right: 30px;
        display: block;
        z-index: 9999;
        height: 30px;
        opacity: 0;
        transition: .6s 0.5s ease-in-out;
        .menu-txt {
            color: currentColor;
            transition: .6s 0.5s ease-in-out;
        }
        .line {
            position: absolute;
            top: 14px;
            left: 3px;
            height: 2px;
            background-color: currentColor;
            border-radius: 0;
            transition: .6s 0.5s ease-in-out;
            transform: rotate(0deg);
            width: 28px;
        }
    }
    &.is-open {
        .menu-lines {
            opacity: 1;
            .line-1 {
                transform: rotate(135deg);
            }
            .line-2 {
                transform: rotate(-135deg);
            }
        }
    }
    &.position-top, &.position-bottom {
        &.is-open {
            transform: translateY(0);
        }
    }
    .title-bar-title {
        display: none;
        font-weight: 500;
        margin-bottom: 30px;
        & > a {
            color: $white;
            display: block;
            & > img {
                display: block;
                max-height: 48px;
                width: auto;
                & + span {
                    display: none;
                }
            }
            & > span {
                display: block;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
}
.off-canvas-content {
    /*margin-left: 70px !important;
    margin-right: 15px;*/
    @include mobile {
        margin-left: 10px !important;
    }
    @include desktop {
        display: none;
    }
}

/** */
.dropdown.menu li {
    & > ul {
        display: none;
        &.js-dropdown-active {
            display: block;
        }
    }
}

/** */
/** @todo swipers slides*/
.swiper-controls {
    .swiper-button {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        @include box(38px);
        margin-top: 0;
        @include mobile {
            @include box(32px);
        }
        &::before {
            color: $color1;
            font-size: 20px;
            font-weight: 300;
        }
        svg {
            fill: $color1;
            color: $color1;
            font-size: 18px;
        }
        &:focus, &:hover {
            outline: none;
            opacity: 1;
            &::before {
                opacity: 0.5;
            }
        }
    }
    .swiper-pagination {
        position: relative;
        white-space: nowrap;
        margin-top: 30px;
        &.swiper-pagination-fraction {
            padding: 0;
        }
        &.swiper-pagination-bullets {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            .swiper-pagination-bullet {
                @include box(5px);
                border-radius: 2px;
                opacity: 0.6;
                margin: 5px;
                transition: 0.3s;
                background-color: lighten($black, 35);
                box-shadow: 0 0 0 2px lighten($black, 35);
                @include mobile {
                    @include box(4px);
                }
                &:focus {
                    outline: none;
                }
                &:hover {
                    background-color: $color2;
                    box-shadow: 0 0 0 2px $color2;
                }
                &.swiper-pagination-bullet-active {
                    opacity: 1;
                    width: 15px;
                    background-color: lighten($color2, 10);
                    box-shadow: 0 0 0 2px lighten($color2, 10);
                    &:hover {
                        background-color: $color2;
                        box-shadow: 0 0 0 2px $color2;
                    }
                }
            }
            &.swiper-pagination-bullets-dynamic {
                .swiper-pagination-bullet {
                    opacity: 0.3;
                    border-radius: 50%;
                    &.swiper-pagination-bullet-active {
                        width: 5px;
                        opacity: 1;
                    }
                }
            }
        }
        &.swiper-pagination-progressbar {
            padding: 0;
            position: absolute;
            bottom: 0;
            top: auto;
            right: auto;
            left: 50%;
            width: 300px;
            transform: translateX(-50%);
            max-width: 90vw;
            height: 2px;
            border-radius: 8px;
            background: #eee;
            .swiper-pagination-progressbar-fill {
                border-radius: 8px;
                background-image: linear-gradient(90deg, lighten($color1, 10) 0%, lighten($color2, 10) 100%);
            }
        }
    }
    .swiper-progress {
        height: 2px;
        background-color: $white;
        border-radius: 8px;
        &::after {
            content: "";
            width: 0;
            transition: width 0s;
            background-image: linear-gradient(90deg, lighten($color1, 10) 0%, lighten($color2, 10) 100%);
            height: 2px;
            position: absolute;
        }
        &.progress {
            &::after {
                width: 100%;
                transition: width 6s linear;
            }
        }
    }
    &.normal-controls {
        .swiper-button {
            opacity: 1;
            border: none !important;
            @include box(12px);
            &::before, &::after {
                display: none;
            }
        }
    }
}

/** */
.swiper-section {
    position: relative;
}
.swiper-marquee {
    .swiper-wrapper {
        transition-timing-function: linear !important;
        touch-action: manipulation;
    }
}
.swiper {
    .swiper-slide {
        height: auto;
        overflow: unset;
        .item {
            position: relative;
            height: 100%;
        }
    }
    &.swiper-initialized {
        z-index: 3;
        & ~ .swiper-controls {
            .swiper-button {
                transform: translateY(-50%);
                @include flex-center;
                background: none;
                border: 1px solid transparentize($color1, 0.5);
                border-radius: 100%;
                z-index: 99;
                &.swiper-button-prev {
                    left: 10px;
                    @include desktop {
                        left: 20px;
                    }
                }
                &.swiper-button-next {
                    right: 10px;
                    @include desktop {
                        right: 20px;
                    }
                }
                &:hover {
                    opacity: 0.8;
                }
                &:after {
                    display: none;
                }
            }
        }
        &:hover {
            & ~ .swiper-controls .swiper-button {
                opacity: 0.8;
            }
        }
    }
}

/** */
.horizontal-menu {
    &.menu {
        & > li {
            align-items: center;
            display: flex;
            position: relative;
            & > a {
                align-items: center;
                display: flex;
                line-height: normal;
                padding: 0;
                position: relative;
                &:hover {
                    background-color: unset;
                }
            }
            &.is-dropdown-submenu-parent {
                & > a {
                    padding-right: 20px;
                    &::after {
                        border: solid currentColor;
                        border-width: 0 2px 2px 0;
                        margin-top: -2px;
                        padding: 2px;
                        right: 6px;
                        transform: rotate(45deg) translateY(-2px);
                        width: 6px;
                    }
                }
            }
            & > .menu {
                &.is-dropdown-submenu {
                    top: 100%;
                }
            }
            .menu {
                &.vertical {
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                }
                &.is-dropdown-submenu {
                    background-color: #fff;
                    border: 1px solid transparentize($white, 0.9);
                    box-shadow: 0 0 3px 1px transparentize($black, 0.9);
                    max-width: unset;
                    min-width: 240px;
                    width: auto;
                    z-index: 999;
                }
                li {
                    position: relative;
                    & > a {
                        background: none;
                        color: $black;
                        position: relative;
                        &:hover {
                            background: #f9f9f9;
                            color: $color2;
                        }
                    }
                    &:hover {
                        transition: all 0.15s linear;
                    }
                }
                .is-active, .active {
                    transition: all 0.15s linear;

                    & > a {
                        background: none;
                    }
                }
            }
            &:hover {
                .menu {
                    &.is-dropdown-submenu {
                        opacity: 1;
                        transition: all 0.2s ease 0s;
                        visibility: visible;
                    }
                }
                & > a {
                    background-color: unset;
                }
            }
            &.is-active, &.active {
                & > a {
                    background-color: unset;
                }
            }
        }
    }
    .is-dropdown-submenu {
        .is-dropdown-submenu-parent {
            &.opens-right {
                & > a {
                    &::after {
                        border: none;
                        @include box(6px);
                        border-right: 1px solid currentColor;
                        border-top: 1px solid currentColor;
                        margin-top: 0;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }
            &.opens-left {
                & > a {
                    &::after {
                        border: none;
                        border-right: 1px solid $light-gray;
                        border-top: 1px solid $light-gray;
                        @include box(7px);
                        left: auto;
                        margin-top: 0;
                        right: 14px;
                        top: 50%;
                        transform: translateY(-50%) rotate(225deg);
                    }
                }
            }
        }
    }
}

/** */
.terms-menu.menu {
    padding: 10px 0;
    & > li {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        line-height: normal;
        position: relative;
        &:not(:last-child) {
            &::after {
                color: currentColor;
                content: "/";
                margin: 0 8px;
                opacity: 0.8;
            }
        }
        & > a {
            color: currentColor;
            display: block;
            line-height: normal;
            padding: 0;
        }
    }
}

/** */
.mobile-menu {
    &.menu {
        padding-bottom: 50px;
        padding-top: 50px;
        .active > a, a:hover {
            background: none;
            color: #ccc;
        }
        .submenu-toggle {
            border-color: $light-gray;
            position: absolute;
            @include box(24px);
            right: 15px;
            top: 15px;
            &::after {
                border: solid $white;
                border-width: 0 2px 2px 0;
                padding: 3px;
                position: absolute;
                transform: rotate(45deg) translateY(-7px) translateX(4px);
                width: 7px;
            }
            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(225deg) translateY(4px) translateX(-7px);
                }
            }
        }
        & > li {
            position: relative;
            &.lang-item {
                a {
                    align-items: center;
                    display: flex;
                    font-size: 14px;
                    font-weight: 300;
                    padding-bottom: 0;
                    padding-top: 0;
                    img {
                        display: block;
                    }
                    span {
                        display: block;
                    }
                }
            }
            & > a {
                color: $white;
                display: block;
                font-size: 15px;
                font-weight: 400;
                line-height: 1.8;
                margin-bottom: 0;
                padding-left: 30px;
                text-transform: uppercase;
            }
        }
        .submenu {
            &.nested {
                padding-left: 15px;
            }
            & > li {
                & > a {
                    color: #eee;
                    font-size: 15px;
                    font-weight: 300;
                    &:hover {
                        color: #ccc;
                    }
                }
                &.active {
                    a {
                        color: #ccc;
                    }
                }
            }
        }
    }
}

/** */
.desktop-menu.menu {
    height: 100%;
    & > li {
        padding: 0;
        &.hide-text {
            & > a {
                text-indent: -999999px;
            }
        }
        &.home-icon {
            position: relative;
            & > a {
                height: 16px;
                margin-left: 15px;
                margin-right: 15px;
                padding: 0;
                position: relative;
                width: 20px;
                &::after {
                    @include absolute-full;
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%2349484b" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"></path></svg>');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                }
                &:hover {
                    &::after {
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%2300B38F" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"></path></svg>');
                    }
                }
            }
            &.is-active, &.active {
                & > a {
                    &::after {
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%2300B38F" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"></path></svg>');
                    }
                }
            }
        }
        & > a {
            color: $color1;
            font-size: 15px;
            padding: 0 12px;
            position: relative;
            transition: all 0.25s ease;
            @include not-desktop {
                font-size: 14px;
            }
            &::before {
                background-color: transparent;
                bottom: -9px;
                content: "";
                height: 1px;
                left: 12px;
                position: absolute;
                transform: scaleX(0);
                transform-origin: left;
                transition: all 0.3s cubic-bezier(0.345, 0, 0.145, 0.995);
                width: calc(100% - 24px);
            }
            &:hover, &:active {
                color: $color2;
                &::before {
                    transform: scaleX(1);
                }
            }
        }
        &.is-active, &.active {
            & > a {
                color: $color2;
                &::before {
                    transform: scaleX(1);
                }
            }
        }
        &.support-menu, &.contact-menu {
            a {
                border-radius: 25px;
                color: $white;
                font-family: inherit;
                font-size: 15px;
                font-weight: 500;
                height: 45px;
                padding: 0 20px;
                white-space: nowrap;
                &:hover {
                    box-shadow: 0 1px 1px rgb(0 0 0 / 5%), 0 2px 2px rgb(0 0 0 / 5%), 0 4px 4px rgb(0 0 0 / 5%),
                    0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%);
                    color: $white;
                    text-decoration: none;
                    transform: translateY(-1px);
                }
                &::before {
                    height: 0 !important;
                }
                @include not-desktop {
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
        &.support-menu a {
            box-shadow: inset 0 0 0 1px transparentize($color1, 0.8);
            @include linear-gradient(135deg, transparentize($color1, 0.2));
            @include mobile {
                display: none;
            }
        }

        &.contact-menu a {
            @include mobile {
                display: none;
            }
            &:hover {
                box-shadow: 0 1px 1px transparentize($color3, 0.95), 0 2px 2px transparentize($color3, 0.95),
                0 4px 4px transparentize($color3, 0.95), 0 8px 8px transparentize($color3, 0.95),
                0 16px 16px transparentize($color3, 0.95);
            }
        }
        .menu li > a {
            font-weight: 400;
            font-size: 15px;
        }
    }
}

/** */
.social-menu {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    li {
        margin-right: 15px;
        @include mobile {
            margin-right: 10px;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #666;
            &::before {
                font-size: 17px;
            }
            &:hover {
                color: $color2;
                svg, path {
                    color: $color2;
                    fill: $color2;
                }
            }
        }
        svg, path {
            color: #666;
            fill: #666;
            width: 28px;
            height: 28px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

/**********************************************/

.section-widget1 {
    padding-top: 10px;
    padding-bottom: 10px;
    .item-inner {
        display: flex;
        align-items: baseline;
        color: $color1;
        a {
            display: flex;
            align-items: baseline;
            color: $color2;
        }
        i {
            margin-right: 10px;
            width: 34px;
            height: 34px;
            flex: 0 0 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            border: 1px solid currentColor;
            border-radius: 50%;
            @include mobile {
                margin-right: 5px;
                width: 30px;
                height: 30px;
                flex: 0 0 30px;
                font-size: 15px;
            }
        }
        h6 {
            font-weight: 400;
            color: currentColor;
            margin-bottom: 0;
            font-size: 15px;
            @include mobile {
                font-size: 14px;
            }
        }
    }
    @include not-xdesktop {
        .widget1-inner {
            position: relative;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100px;
                will-change: background-color;
                background-image: linear-gradient(to left, rgba(245, 248, 255, 0.8) 0%, transparent 100%);
                pointer-events: none;
            }
        }
        .grid-x {
            position: relative;
            flex-flow: row nowrap;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;
            overflow-x: auto;
            white-space: nowrap;
            &::-webkit-scrollbar {
                height: 0;
                width: 0;
            }
            & > .cell {
                scroll-snap-align: start;
                scroll-snap-stop: always;
            }
        }
    }
}

/**********************************************/

ul.menu li img {
    display: none;
}

/** */
.site-header {
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        height: 50vh;
        max-height: 130px;
        will-change: background-color;
        background-image: linear-gradient(to top, transparent 0%, #000 100%);
        opacity: 0;
        content: "";
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: 4;
        transition: .3s;
    }
    .inside-header {
        background-color: #ce2033;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        &::before {
            content: '';
            background-image: url(../img/header-bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include absolute-full;
        }
        .inside-search {
            align-items: center;
            justify-content: space-between;
            position: relative;
        }
        .site-logo {
            flex: 0 0 auto;
            min-width: auto;
        }
        .right-group {
            flex: 0 0 auto;
            min-width: auto;
            display: flex;
            align-items: center;
            & > * {
                margin-left: 15px;
            }
        }
        .header-search-dropdown {}
        form.frm-search {}
        .hotline {
            display: flex;
            align-items: center;
            .hotline-inner {
                display: flex;
                align-items: center;
                background-color: #fff;
                padding: 3px 18px 0 18px;
                border-radius: 30px;
                height: 35px;
                &:not(:first-child) {
                    margin-left: 15px;
                }
                &::before {
                    margin-right: 5px;
                    font-weight: 900;
                    opacity: 0.9;
                    color: $color1;
                }
                a {
                    display: block;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
        .mini-cart {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 3px 18px 0 18px;
            border-radius: 30px;
            height: 35px;
            @include not-desktop {
                padding: 3px 10px 0 10px;
            }
            & > a {
                display: flex;
                align-items: center;
                &::before {
                    font-weight: 900;
                    opacity: 0.9;
                    color: $color1;
                }
                span {
                    margin-left: 5px;
                    display: block;
                    font-size: 15px;
                    @include not-desktop {
                        display: none;
                    }
                }
            }
            & > span {}
        }
        .off-canvas-content {}
    }
}

/** */

.mega_menu {
    position: relative;
    z-index: 999;
    .mega-menu-title {
        border: 1px solid #fff;
        background-color: var(--color1);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 52px;
        padding: 0 15px;
        cursor: pointer;
        transition: 0.3s;
        color: var(--white);
        font-size: 16px;
        font-weight: 300;
        &.is-active {
            & + ul.menu {
                display: flex;
                flex-direction: column;
                & > li {
                    flex-grow: 1;
                    & > a {
                        height: 100%;
                    }
                }
            }
        }
        &::before {
            font-size: 20px;
            margin-right: 15px;
        }
        &::after {
            width: 10px;
            margin-right: 0;
            position: absolute;
            right: 15px;
        }
        span {
            display: block;
            text-transform: uppercase;
            padding-top: 1px;
            font-weight: 500;
            font-size: 15px;
        }
    }

    & > .desktop-menu.menu, & > .mobile-menu.menu {
        margin: 0;
        list-style: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        background: var(--white);
        display: none;
        box-shadow: 0 0 24px rgb(51 51 51 / 10%);
        height: auto;
        & > li {
            &:not(:last-child) {
                border-bottom: 1px solid transparentize($color1, 0.9);
            }
            & > a {
                display: flex;
                align-items: center;
                padding: 15px;
                transition: 0.3s;
                font-size: 15px;
                &:hover {
                    opacity: 0.9
                }
                img {
                    display: block;
                    width: 26px;
                    height: 26px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 4px;
                }
                span {
                    display: inline-block;
                    margin-left: 10px;
                    line-height: normal;
                }
            }
            &.is-dropdown-submenu-parent {
                &.opens-right {
                    & > a::after {
                        content: '\f105';
                        font-family: $font-awesome;
                        border: none;
                        margin-top: 0;
                        transform: translateY(-50%);
                        right: 15px;
                        width: auto;
                        height: auto;
                    }
                }
                ul {
                    li {
                        a {
                            line-height: 1.45;
                            transition: 0.3s;
                            font-size: 15px;
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid transparentize($color1, 0.9);
                        }
                        &.is-dropdown-submenu-parent {
                            &.opens-right {
                                & > a::after {
                                    content: '\f105';
                                    font-family: $font-awesome;
                                    border: none;
                                    margin-top: 0;
                                    transform: translateY(-50%);
                                    right: 15px;
                                    width: auto;
                                    height: auto;
                                }
                            }
                        }
                        &.is-active, &.active, &:hover {
                            & > a {
                                background: none;
                                color: $color2;
                            }
                        }
                    }
                }
            }
        }
        &.home_mega_menu, &.mobile-menu {
            /*border-color: var(--color3);*/
            display: flex;
            flex-direction: column;
            & > li {
                flex-grow: 1;
                & > a {
                    height: 100%;
                }
            }
        }
        .menu.vertical {
            display: none;
            &.js-dropdown-active {
                display: block;
            }
        }
        .menu.submenu {
            box-shadow: 0 0 24px rgb(51 51 51 / 10%);
            min-width: 250px;
            border: none;
        }
    }
}
.mega_menu > .mobile-menu.menu {
    box-shadow: none;
    background-color: transparent;
    padding-bottom: 50px;
    padding-top: 50px;
    & > li.is-dropdown-submenu-parent > a::after {
        display: none;
    }
}
.mega_menu > .mobile-menu.menu .menu.submenu {
    min-width: 0;
    box-shadow: none;
}
.mega_menu ul.menu .active > a {
    background: none;
}

/** */
.header-content {
    background-color: #fff;
    .col-left {
        flex: 0 0 auto;
        width: 22%;
        min-width: 250px;
        @include not-desktop {
            display: none;
        }
        &.col-menu {
            .mega_menu {
                position: relative;
                z-index: 999;
                .mega-menu-title {}
                & > ul.menu {}
            }
        }
    }
    .col-right {
        flex: 1 1 0;
        min-width: 0;
        display: flex;
        justify-content: space-between;
        .menu-inner {
            padding-left: 20px;
            padding-right: 20px;
            @include not-desktop {
                padding-left: 0;
                padding-right: 0;
                flex: 1 1 0;
                min-width: 0;
                position: relative;
                overflow: hidden;
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100px;
                    will-change: background-color;
                    background-image: linear-gradient(to left, #fff 0%, transparent 100%);
                    pointer-events: none;
                }
                .dropdown.menu  {
                    position: relative;
                    flex-flow: row nowrap;
                    overflow-y: hidden;
                    scroll-snap-type: x mandatory;
                    overflow-x: auto;
                    white-space: nowrap;
                    & > li {
                        scroll-snap-align: start;
                        scroll-snap-stop: always;
                    }
                    &::-webkit-scrollbar {
                        height: 0;
                        width: 0;
                    }
                }
            }
        }
        .desktop-menu.menu > li > a {
            text-transform: uppercase;
            padding: 0 20px;
            font-weight: 500;
            @include not-desktop {
                padding: 0 10px;
            }
        }
    }
    &::after {
        box-shadow: 0 0 6px 1px rgb(0 0 0 / 2%);
        content: "";
        height: 100%;
        left: 0;
        opacity: .8;
        position: absolute;
        top: 0;
        transition: all .5s ease-in-out;
        width: 100%;
        z-index: -1;
    }
}

/** */
.contact-sale {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    & > .contact-title {
        background-color: #fd5622;
        color: #fff;
        max-height: 100%;
        display: flex;
        align-items: center;
        padding: 8px 15px;
        border-radius: 30px;
        font-size: 15px;
        font-weight: 500;
        &::after {
            margin-left: 8px;
        }
        &.is-active {
            background-color: rgba(253, 86, 34, 0.8);
            backdrop-filter: blur(15px);
            & + .contact-sale-wrapper {
                display: block;
            }
        }
    }
    .contact-sale-wrapper {
        transition: 0.3s;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 0;
        background: var(--white);
        display: none;
        box-shadow: 0 0 24px rgba(51, 51, 51, 0.1);
        height: auto;
        width: auto;
        max-width: 100%;
        min-width: 200px;
        ul {
            margin: 0;
            list-style: none;
            padding: 20px 25px;
            display: flex;
            flex-direction: column;
            li {
                & > label {
                    background-color: $color1;
                    font-weight: 500;
                    padding-left: 10px;
                    padding-right: 10px;
                    border-radius: 20px;
                    display: inline-block;
                    color: #fff;
                }
                & > p {
                    a {
                        white-space: nowrap;
                        color: transparentize($color-red, 0.2);
                        font-weight: 700;
                        margin-top: 5px;
                        font-size: 18px;
                        letter-spacing: 1px;
                        &:hover {
                            color: $color-red;
                        }
                    }
                }
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
    &:hover {
        & > .contact-title {
            background-color: rgba(253, 86, 34, 0.8);
            backdrop-filter: blur(15px);
        }
        .contact-sale-wrapper {
            display: block;
        }
    }
}

/** */
.site-logo {
    flex: 0 0 auto;
    width: auto;
    .logo {
        margin-bottom: 0;
    }
    .custom-logo-link {
        display: block;
    }
    .custom-logo {
        max-height: 65px;
        filter: brightness(0) invert(1);
        @include wp-mobile {
            max-height: 45px;
        }
        @include mobile {
            max-height: 35px
        }
    }
}

/** */
.inside-search {
    .header-search-dropdown {}
    .frm-search {
        flex: 1 1 0;
        margin-left: 45px;
        display: flex;
        align-items: center;
        position: relative;
        min-width: 0;
        margin-right: 30px;
        justify-content: flex-end;
        @include not-desktop {
            margin-right: 0;
        }
        @include mobile {
            display: none;
        }
        input {
            background: #fff;
            font-weight: 300;
            color: $medium-black;
            margin-bottom: 0;
            border: none;
            height: 35px;
            border-radius: 30px;
            padding: 3px 36px 0 18px;
            max-width: 560px;
            &::placeholder {
                color: $medium-black;
            }
        }
        button[type="submit"] {
            position: absolute;
            cursor: pointer;
            right: 18px;
            &::before {
                font-weight: 900;
                color: $color1;
            }
            span {
                display: none;
            }
        }
    }
}

/** */
.search-dropdown--wrap {
    .trigger-s {
        @include box(30px);
        border-radius: 50%;
        @include flex-center;
        cursor: pointer;
        border: 1px solid rgb(255 255 255 / 50%);
        &::before {
            border-radius: 99px;
            color: #fff;
            flex: 0 0 30px;
            height: 30px;
            max-width: 30px;
            padding: 4px;
            display: flex;
            justify-content: center;
            font-weight: 400;
        }
        & > span {
            display: none;
        }
        &:hover {
            &::before {
                color: #eee;
            }
        }
    }
    .dropdown-pane {
        background-color: #f6f5f7;
        border: none;
        bottom: auto;
        opacity: 0;
        padding: 12px;
        position: absolute;
        transition: all 0.15s linear;
        width: 100%;
        z-index: 10001;
        &.is-open {
            opacity: 1;
            transition: all 0.2s linear;
        }
        @include not-desktop {
            left: unset !important;
            max-width: calc(100vw - 65px);
            right: 5px !important;
            width: auto;
        }
        @include mobile {
            width: calc(100vw - 20px);
        }
        @include desktop {
            left: 0 !important;
        }
        .frm-container {
            flex: 0 0 300px;
            margin-left: auto;
            margin-right: 0;
            max-width: 300px;
            position: relative;
            &::before {
                border-color: transparent transparent #f6f5f7 transparent;
                border-style: solid;
                border-width: 0 13px 15px;
                content: "";
                left: 0;
                position: absolute;
                top: -20px;
            }
            input[type="search"] {
                border-radius: 30px;
                box-shadow: none;
                font-size: 12px;
                font-weight: 400;
                height: 34px;
                margin-bottom: 0;
                outline: none;
                padding: 0 30px 0 10px;
                max-width: 100%;
                width: 300px;
            }
            .btn-s {
                background-color: $color3;
                @include box(27px);
                border-radius: 50%;
                cursor: pointer;
                outline: none;
                padding: 3px;
                position: absolute;
                right: 3px;
                top: 3px;
                @include flex-center;
                & > span {
                    display: none;
                }
                svg {
                    @include box(16px);
                    fill: $white;
                }
                &::before {
                    color: $white;
                }
            }
            .trigger-s-close {
                display: none;
            }
        }
    }
}

/** */
.iconbox {
    list-style: none;
    margin: 0;
    li {
        span.img {
            display: block;
            img {
                max-width: 100%;
                display: block;
            }
        }
    }
}

/** */
.site-footer {
    position: relative;
    padding-top: 80px;
    @include not-desktop {
        padding-top: 50px;
    }
}

/** */
.footer-widgets {
    position: relative;
    padding-bottom: 30px;
    .grid-x {
        @include grid-gap(60px, 15px);
    }
    .footer-widget {
        @include grid-column2(4, 2, 1, 60, 15);
        .footer-title {
            text-transform: uppercase;
            color: $medium-black;
            margin-bottom: 20px;
            display: block;
            font-weight: 600;
            @include mobile {
                margin-bottom: 10px;
            }
        }
        .footer-text {
            color: currentColor;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 30px;
        }
        .iconbox {
            li {
                display: flex;
                i, em, &::before {
                    top: 0;
                    position: relative;
                    flex: 0 0 28px;
                    max-width: 28px;
                    font-size: 15px;
                    color: $color1;
                    border: 1px solid $color1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    margin-right: 10px;
                    border-radius: 50%;
                }
                .iconbox-title {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 1.5;
                    a {
                        color: currentColor;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    .title, & > p {
                        line-height: 1.5;
                    }
                    &.lines {
                        a {
                            position: relative;
                            &:not(:last-child) {
                                &::after {
                                    content: ' - ';
                                }
                            }
                        }
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
        &.footer-widget-1 {
            position: relative;
            @include desktop {
                &::before {
                    content: "";
                    position: absolute;
                    right: -30px;
                    height: 90%;
                    width: 1px;
                    top: 5%;
                    background-color: rgb(0 0 0 / 10%);
                }
            }
        }
    }
}

/** */
.footer-credit {
    position: relative;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid rgb(0 0 0 / 5%);
    color: $medium-black;
    .hd {
        opacity: 0.8;
    }
}

/** */
.title-container {
    & > .subtitle {
        color: $color2;
        text-transform: uppercase;
        font-style: normal;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
        @include mobile {
            margin-bottom: 5px;
        }
    }
    & > .heading-title {
        font-weight: 600;
        color: $color1;
        /*text-align: center;
        text-transform: uppercase;*/
    }
    & > .desc {
        color: $medium-black;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 0.5rem;
        opacity: 0.95;
    }
}

/** */
.section.section-products {
    .title-container {
        text-align: center;
    }
    .product-container {
        margin-top: 30px;
        .cell {
            margin-bottom: 7.5px;
            margin-top: 7.5px;
        }
        article.item {
            & > a {
                display: block;
                position: relative;
                overflow: hidden;
            }
            .res {
                background-color: $white;
            }
            h6 {
                position: relative;
                margin-bottom: 0;
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    padding: 15px;
                    &::after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 0;
                        content: "";
                        background-color: $color1;
                        transition: 0.5s;
                        opacity: 0;
                    }
                }
            }
            &:hover {
                .res {
                    opacity: 0.9;
                    &::after {}
                }
                h6 {
                    span {
                        &::after {
                            width: 100%;
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
        .btn-link {
            a {
                @include _effect2;
                transition: 0.5s ease;
                padding: 10px 20px;
                background-color: $color1;
                font-weight: 500;
                color: #eee;
                font-size: 15px;
                line-height: normal;
                margin-bottom: 0;
                margin-top: 30px;
            }
        }
    }
}

/** */
.section.section-news {
    margin-top: 60px;
    @include mobile {
        margin-top: 30px;
    }
    .heading {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 20px;
        &.heading-line {
            position: relative;
            z-index: 1;
            &::after, &::before {
                background-color: $color1;
                content: "";
                height: 1px;
                /*height: .1px;*/
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            &::before {
                background: linear-gradient(90deg, #fff, $color1);
                left: 0;
                width: 50%;
            }
            &::after {
                background: linear-gradient(270deg, #fff, $color1);
                right: 0;
                width: 50%;
            }
            .heading-title {
                margin-bottom: 0;
                background-color: $color1;
                border: 1px solid $color1;
                border-radius: 30px;
                color: #fff;
                padding: 5px 20px;
                font-weight: 600;
                z-index: 9;
            }
        }
    }
    &.section-carousels {
        .title-container {}
        .swiper-controls {
            .swiper-button {
                border-radius: 0;
                opacity: 0.8;
                border-width: 1px;
                width: 32px;
                height: 32px;
                transform: translateY(0);
                top: -62px;
                svg {
                    width: 18px;
                    height: 18px;
                }
                &.swiper-button-next {
                    right: 0;
                }
                &.swiper-button-prev {
                    left: unset;
                    right: 50px;
                }
            }
        }
    }
    .news-container {
        margin-top: 30px;
        article.item {
            border: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 1px;
            .cover-content {
                display: flex;
                flex-direction: column;
                background-color: #fff;
                padding: 20px;
                .terms {
                    a {
                        font-size: 13px;
                        color: $color1;
                        padding: 2px 10px;
                        border-radius: 15px;
                        border: 1px solid;
                        &:hover {
                            color: $color2;
                        }
                    }
                }
                h6 {
                    margin-top: 15px;
                    margin-bottom: 10px;
                    a {
                        font-weight: 600;
                        &:hover {
                            opacity: 0.9;
                            color: $color2;
                        }
                    }
                }
                .excerpt {
                    color: $medium-black;
                    font-size: 15px;
                    line-height: inherit;
                    font-weight: 300;
                    margin-bottom: 0;
                    @include line-clamp(3);
                    p {
                        line-height: 1.5;
                    }
                }
                .view-more {
                    color: $color2;
                    /*display: inline-flex;*/
                    align-items: center;
                    font-size: 15px;
                    margin-top: 20px;
                    font-weight: 300;
                    flex-direction: row-reverse;
                    display: none;
                    &::before {
                        margin-left: 5px;
                        font-weight: 400;
                        position: relative;
                        top: 2px;
                    }
                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
    }
}

/** */
.section.section-partners {
    margin-top: 50px;
    @include mobile {
        margin-top: 30px;
    }
    .title-container {
        text-align: center;
        .heading-title {
            color: $color2;
            font-weight: 600;
            text-transform: uppercase;
        }
        .desc {
            font-weight: 300;
            line-height:1.5;
        }
    }
    .partners-wrapper {
        display: flex;
        flex-flow: row wrap;
        @include grid-gap(0, 0);
        list-style: none;
        margin: 30px 0 0;
        background-color: #fff;
        border-top: 1px solid rgb(0 0 0 / 5%);
        border-left: 1px solid rgb(0 0 0 / 5%);
        .partner-item {
            @include grid-column2(6, 5, 4, 0, 0);
            padding: 40px;
            border-right: 1px solid rgb(0 0 0 / 5%);
            border-bottom: 1px solid rgb(0 0 0 / 5%);
            @include not-desktop {
                padding: 20px;
            }
            @include mobile {
                padding: 10px;
            }
        }
        a {
            display: block;
        }
        img {
            filter: brightness(0.8);
            transition: 0.3s;
        }
        &:hover {
            img {
                filter: brightness(1);
            }
        }
        &.swiper-section {
            border: none;
            box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
        }
    }
}

/** */
.section.carousels-section {
    position: relative;
    .swiper-controls {
        .swiper-button-prev {}
        .swiper-pagination {
            position: absolute;
            bottom: 30px;
        }
    }
    article.item {
        .overlay {
            img {
                width: 100%;
            }
        }
        .content-wrap {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            @include not-desktop {
                display: none;
            }
        }
        .content-inner {
            @include right-fullwidth;
            .inner {
                color: $white;
                max-width: 40vw;
                margin-top: 90px;
                @include not-xxdesktop {
                    max-width: 65vw;
                    padding-left: 80px;
                }
            }
            .sub-title {
                text-transform: uppercase;
                color: #eee;
                margin-bottom: 5px;
                font-weight: 400;
                font-size: 16px;
            }
            .html-title {
                line-height: 1.1;
                color: currentColor;
                text-shadow: 1px 0 0 rgb(24 27 49 / 10%), -1px 0 0 rgb(24 27 49 / 10%), 0 1px 0 rgb(24 27 49 / 10%), 0 -1px 0 rgb(24 27 49 / 10%), 1px 1px rgb(24 27 49 / 10%), -1px -1px 0 rgb(24 27 49 / 10%), 1px -1px 0 rgb(24 27 49 / 10%), -1px 1px 0 rgb(24 27 49 / 10%);
            }
            .html-desc {
                text-shadow: 1px 0 0 rgb(24 27 49 / 10%), -1px 0 0 rgb(24 27 49 / 10%), 0 1px 0 rgb(24 27 49 / 10%), 0 -1px 0 rgb(24 27 49 / 10%), 1px 1px rgb(24 27 49 / 10%), -1px -1px 0 rgb(24 27 49 / 10%), 1px -1px 0 rgb(24 27 49 / 10%), -1px 1px 0 rgb(24 27 49 / 10%);
                font-size: 15px;
                color: #eee;
            }
            .btn-link {
                margin-top: 25px;
                a {
                    background-color: $color1;
                    @include _effect2;
                }
            }
        }
    }
}

/** */
.section.block-padding {
    padding-top: 20px;
    padding-bottom: 30px;
    @include mobile {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
.entry-post {
    strong, b {
        font-weight: 700;
    }
}

/** */
.breadcrums-container {
    padding-bottom: 30px;
    .breadcrums {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        flex-wrap: wrap;
        li {
            font-size: 14px;
            font-weight: 300;
            & > span {}
            a {
                &:hover {
                    color: $medium-black;
                }
            }
            i {
                font-size: 11px;
                margin-left: 5px;
                margin-right: 7px;
                font-weight: 300;
            }
        }
    }
}

/** */
.section.single-post {
    .sharing_toolbox {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 40px;
        margin-top: 110px;
        padding-right: 15px;
        position: sticky;
        top: 220px;
        @include mobile {
            display: none;
        }
    }
    .grid-container {
        align-items: flex-start;
        display: flex;
    }
    .terms {
        margin: 0;
        padding-bottom: 30px;
        padding-top: 50px;

        a {
            border: 1px solid $color2;
            border-radius: 7px;
            color: $color2;
            font-size: 14px;
            margin-right: 15px;
            padding: 5px 10px;
            transition: 0.3s;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                border-color: $color2;
                color: $color2;
            }
        }
    }
    .excerpt {
        opacity: 0.9;
        margin-bottom: 30px;
        margin-top: 15px;
        display: flex;
        align-items: baseline;
        font-weight: 300;
        @include mobile {
            margin-bottom: 15px;
            margin-top: 10px;
        }
        &::before {
            font-weight: 900;
            margin-right: 15px;
            color: $color-red;
            font-size: 150%;
        }
        blockquote {
            background-color: rgb(177 177 177 / 10%);
            border-left: none;
            box-shadow: inset 0 0 0 1px rgb(177 177 177 / 10%);
            box-sizing: border-box;
            color: lighten($black, 10);
            font-weight: 300;
            height: auto;
            margin-bottom: 30px;
            max-width: 100%;
            padding: 30px 30px 30px 80px;
            position: relative;
            &::first-letter {
                font-size: 18px;
                font-weight: 700;
                margin-right: 1px;
            }
            &::before {
                color: lighten($color2, 10);
                content: "“";
                display: block;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 150px;
                font-style: normal;
                left: 10px;
                line-height: 1;
                position: absolute;
                top: -16px;
            }
        }
    }
    .col-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 960px;
        .content {
            margin-bottom: 50px;
        }
    }
}

/** */
.section.archive-posts {
    h1 {
        text-align: center;
    }
    .archive-desc {
        text-align: center;
    }
    .posts_list {
        margin-top: 30px;
        justify-content: center;
        .cell {
            margin-bottom: 20px;
        }
        article.item {
            background-color: rgb(255 255 255 / 90%);
            backdrop-filter: blur(6px);
            height: 100%;
            .cover-content {
                .terms {}
                h6 {
                    margin-top: 20px;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-bottom: 0;
                    a {
                        color: $black;
                        &:hover {
                            color: $medium-black;
                        }
                    }
                }
                .excerpt {
                    padding-left: 15px;
                    padding-right: 15px;
                    color: $medium-black;
                    font-size: 15px;
                    line-height: inherit;
                    font-weight: 400;
                    margin-bottom: 0;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 10px;
                    * {
                        color: currentColor;
                    }
                    @include mobile {
                        display: none;
                    }
                }
                .view-more {
                    color: $color-red;
                    display: inline-flex;
                    align-items: center;
                    font-size: 14px;
                    margin-top: 15px;
                    justify-content: flex-end;
                    font-weight: 300;
                    padding: 0 15px 20px 15px;
                    span {
                        color: currentColor;
                    }
                    i {
                        margin-left: 5px;
                        color: currentColor;
                    }
                }
            }
        }
    }
}

/** */
.price-sp-dmsp, .products-prices {
    .price {
        display: flex;
        justify-content: left;
        margin-top: 10px;
        .ribbon {
            display: none;
        }
        .price-amount {
            color: $color-red;
           font-weight: 700;
            & > .price-currencySymbol {
                color: currentColor;
                font-weight: 400;
                margin-left: 2px;
            }
            &.price-km {
                color: $color1;
                font-weight: 300;
                font-size: 14px;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }
    }
}

/** */
.single-search-box {
    margin-bottom: 30px;
    margin-top: 20px;
    display: none;
    @include mobile {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .frm-search {
        display: flex;
        align-items: center;
        input {
            background: #fff;
            height: 40px;
            padding: 0 20px;
            font-size: 15px;
            margin-bottom: 0;
            border: none;
            border-radius: 30px;
            margin-right: 20px;
            color: $black;
        }
        button[type="submit"] {
            background-color: $color1;
            transition: 0.3s;
            color: #fff;
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            border: none;
            border-radius: 30px;
            @include desktop {
                padding: 0 50px;
            }
            &::before {
                margin-right: 10px;
                font-weight: 400;
                color: #eee;
            }
            span {
                color: currentColor;
                white-space: nowrap;
                font-weight: 400;
                text-transform: uppercase;
            }
            &:hover {
                background-color: $color2;
            }
        }
    }
}

/** */
.section.archive-search {
    .single-search-box {
        @include desktop {
            .frm-search button[type=submit] {
                width: calc(100% * 4 / 12);
                justify-content: center;
            }
        }
    }
    .section-subtitle {
        margin: 0 -10px;
        list-style: none;
        display: flex;
        align-items: center;
        li {
            padding: 10px;
            a {
                background-color: #fff;
                border-radius: 8px;
                color: $medium-black;
                display: block;
                font-size: 15px;
                border: 1px solid rgb(204 204 204 / 60%);
                padding: 5px 15px;
                &:hover {
                    background-color: $color2;
                    border-color: $color2;
                    color:#fff;
                }
            }
        }
    }
    .list-post-search-container {
        .left {}
        .right {
            @include not-desktop {
                display: none;
            }
        }
        .section-searchbox {
            margin-top: 20px;
            .not-found {
                background-color: #fff;
                color: $black;
                padding: 30px;
                border-radius: 7px;
            }
            ul.searchbox {
                margin: 0;
                list-style: none;
                li {
                    margin-bottom: 20px;
                    article.item {
                        padding: 20px 30px;
                        border-radius: 7px;
                        background-color: #fff;
                        /*backdrop-filter: blur(6px);*/
                        box-shadow: 0 0 6px 1px rgb(0 0 0 / 1%);
                        .terms {
                            a {
                                color: $medium-black;
                                margin-bottom: 0;
                                text-transform: uppercase;
                                font-weight: 300;
                            }
                        }
                        h5, h6 {
                            font-weight: 700;
                            margin-bottom: 10px;
                            a {
                                color: $black;
                                &:hover {
                                    color: $medium-black;
                                }
                            }
                        }
                        .desc {
                            color: $medium-black;
                            font-size: 15px;
                        }
                        .view-more {
                            color: $color2;
                            letter-spacing: 0.1px;
                            font-weight: 500;
                            font-size: 15px;
                            margin-top: 15px;
                            span {
                                color: currentColor;
                            }
                            i {
                                margin-left: 5px;
                                color: currentColor;
                            }
                            &:hover {
                                color: $color-red;
                            }
                        }
                    }
                }
            }
        }
    }
}

/** */
.sidebar-wrapper {
    margin-top: 20px;
    position: sticky;
    top: 30px;
    margin-bottom: 20px;
    .sidebar-box {
        .box-inner {
            border-radius: 7px;
            padding: 20px 30px;
            background-color: #fff;
            /*backdrop-filter: blur(6px);*/
            box-shadow: 0 0 6px 1px rgb(0 0 0 / 1%);
            h6 {
                text-transform: uppercase;
                color: $black;
            }
            .iconbox {
                li {
                    display: flex;
                    align-items: baseline;
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                    i {
                        top: 0;
                        position: relative;
                        flex: 0 0 28px;
                        max-width: 28px;
                        font-size: 15px;
                        color: $color-red;
                        border: 1px solid transparentize($color-red, 0.3);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                        margin-right: 10px;
                        border-radius: 50%;
                    }
                    .iconbox-title {
                        font-weight: 400;
                        font-size: 15px;
                        a {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

/** */
.section.single-contact {
    .contact-wrapper {
        margin-top: 50px;
        @include mobile {
            margin-top: 30px;
        }
        .contact-inner {
            display: flex;
            flex-flow: row wrap;
            @include wp-mobile {
                display: block;
            }
            .infomation {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 40px;
                margin-top: 0;
                background-color: transparentize($color1, 0.3);
                backdrop-filter: blur(15px);
                @include wp-mobile {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 30px;
                    margin-bottom: 30px;
                }
                @include mobile {
                    padding: 20px;
                }
            }
            .map {
                flex: 1 1 0;
                min-width: 0;
                @include wp-mobile {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .responsive-embed {
                    margin-bottom: 0;
                }
            }
        }

    }
    ul.infomation {
        list-style: none;
        margin: 30px 0 0 0;
        li {
            margin-bottom: 6px;
            display: flex;
            color: #fff;
            border-bottom: 1px solid rgb(255 255 255 / 5%);
            padding-bottom: 6px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            i, &::before {
                position: relative;
                top: -2px;
                flex: 0 0 30px;
                max-width: 30px;
                font-size: 17px;
                color: #eee;
                font-weight: 300;
            }
            .iconbox-title {
                flex: 1 1 0;
                min-width: 0;
                font-weight: 500;
                a {
                    font-weight: 500;
                    color: #fff;
                    &:hover {
                        color: #eee;
                    }
                    &::before {
                        background-color: #eee;
                    }
                }
            }
        }
    }
}

/** */
#cart-header {
    margin-left: 15px;
    position: relative;
    @include mobile {
        margin-left: 15px;
    }
    & > span {
        position: absolute;
        top: -9px;
        right: 10px;
        width: auto;
        height: 18px;
        min-width: 18px;
        border-radius: 50%;
        background-color: $color1;
        color: #fff;
        @include flex-center;
        font-size: 12px;
        transition: 0.3s;
    }
}

/** */
.lang-box-frm {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .lang {
        height: 16px;
        img {
            display: block;
            height: 15px;
            @include mobile {
                height: 13px;
            }
        }
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}
.is-invalid-input {
    color: $color-red !important;
    &::placeholder {
        color: $color-red !important;
    }
}

.header-search-dropdown {
    @include wp-desktop {
        display: none;
    }
}

.inside-search .frm-search {
    @include wp-mobile {
        display: none;
    }
}

/** */
.product-image {
    .MagicZoom {
        display: block;
        width: 100%;
        & > figure {
            width: 100%;
        }
    }
}

/** */
select.multi-select {
    opacity: 0;
    &.jqmsLoaded {
        display: none;
    }
}

/**********************************/
/**********************************/

.section.section-imgs1 {
    margin-top: 20px;
    @include mobile {
        margin-top: 15px;
    }
    .overlay {
        .thumbs {
            overflow: hidden;
            display: block;
            img {
                width: 100%;
                display: block;
            }
        }
    }
}
.imgs-inner {
    /*.overlay {
        .thumbs {
            border-radius: 15px;
            overflow: hidden;
            display: block;
        }
    }*/
    &.img1-inner {
        @include not-desktop {
            margin-top: 15px;
        }
        & > a {
            &:not(:first-child) {
                margin-top: 20px;
                @include mobile {
                    margin-top: 15px;
                }
            }
        }
    }
}

/** */
.section.section-slides2 {
    margin-top: 20px;
    @include mobile {
        margin-top: 15px;
    }
}

/** */
.section.section-imgs2 {
    margin-top: 20px;
    @include mobile {
        margin-top: 15px;
    }
    .imgs-inner {
        img {
            display: block;
            width: 100%;
        }
    }
}

/** */
.section.section-widget2 {
    margin-top: 20px;
    @include mobile {
        margin-top: 15px;
    }
    .grid-x {
        @include grid-gap2(4, 2, 1, 0, 0);
        background-color: #fff;
        /*box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);*/
        border: 1px solid rgb(0 0 0 / 5%);
        border-top: none;
        border-right: none;
        & > .cell {
            border-top: 1px solid rgb(0 0 0 / 5%);
            border-right: 1px solid rgb(0 0 0 / 5%);
            /*&:not(:last-child) {
                .item-inner {
                    border-right: 1px solid rgb(0 0 0 / 5%);
                }
            }*/
        }
        .item-inner {
            display: flex;
            justify-content: center;
            padding: 15px;
            height: 100%;
            .overlay {
                flex: 0 0 40px;
                max-width: 40px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile {
                    flex: 0 0 45px;
                    max-width: 45px;
                    margin-right: 15px;
                }
                img {
                    display: block;
                    width: 100%;
                }
            }
            .content {
                flex: 0 0 auto;
                min-width: auto;
                h6 {
                    color: $color1;
                    margin-bottom: 0;
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                }
                .desc {
                    color: transparentize($color1, 0.1);
                    font-size: 14px;
                    p {
                        color: currentColor;
                    }
                }
             }
        }
    }
}

/** */
.section.tab-products-filter {
    .heading-title {
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        color: $color1;
    }
    .w-filter-tabs {
        margin-top: 30px;
        @include mobile {
            margin-top: 15px;
        }
        .filter-tabs {
            display: flex;
            justify-content: center;
            ul {
                display: inline-flex;
                align-items: center;
                margin: 0;
                list-style: none;
                justify-content: center;
                flex-flow: row wrap;
                gap: 5px;
                .tabs-inner {
                    background-color: #fff;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    .tab-title {
                        padding: 12px 20px;
                        font-size: 15px;
                        line-height: normal;
                        @include mobile {
                            padding: 10px;
                            font-size: 14px;
                            font-weight: 300;
                        }
                        &.current {
                            background-color: $color1;
                            color: #fff;
                            &:hover {
                                color: #fff;
                            }
                        }
                        &:hover {
                            color: $color2;
                        }
                    }
                    /*&:not(:last-child) {
                        border-right: 1px solid rgba(0, 0, 0, 0.05);
                    }*/
                }
            }
        }
        .filter-tabs-content {
            margin-top: 30px;
            .tabs-panel {
                @include not-desktop {
                    .grid-x {
                        flex-direction: column-reverse;
                        .overlay {
                            margin-top: 15px;
                        }
                    }
                }
            }
            .overlay {
                img {
                    width: 100%;
                    display: block;
                    border-radius: 15px;
                }
            }
        }
    }
}
.section.filter-product-cat {
    margin-top: 60px;
    @include mobile {
        margin-top: 30px;
    }
    .carousel-productcat {}
    .grid-productcat {
        article.item {
            position: relative;
            border-radius: 15px;
            overflow: hidden;
            & > a {
                position: relative;
            }
            h6 {
                transition: 0.3s;
                text-shadow: 1px 0 0 rgba(24, 27, 49, 0.1), -1px 0 0 rgba(24, 27, 49, 0.1), 0 1px 0 rgba(24, 27, 49, 0.1), 0 -1px 0 rgba(24, 27, 49, 0.1), 1px 1px rgba(24, 27, 49, 0.1), -1px -1px 0 rgba(24, 27, 49, 0.1), 1px -1px 0 rgba(24, 27, 49, 0.1), -1px 1px 0 rgba(24, 27, 49, 0.1);
                color: #fff;
                position: absolute;
                left: 0;
                top: 14%;
                font-weight: 500;
                text-transform: uppercase;
                /*font-size: 16px;*/
                margin: 0;
                width: 100%;
                text-align: center;
                span {
                    display: block;
                    padding: 0 10px;
                }
            }
            .after-overlay {
                &::after {
                    @include linear-gradient2(to bottom, $black, transparent);
                    opacity: 0.2;
                    transition: 0.3s;
                }
            }
            &:hover {
                h6 {
                    transform: scale(1.02);
                }
                .after-overlay {
                    &::after {
                        /*@include linear-gradient2(to bottom, $black, transparent);*/
                        opacity: 0;
                    }
                }
            }
        }
    }
}

/** */
.section.grid-products-wrapper {
    margin-top: 60px;
    @include mobile {
        margin-top: 30px;
    }
    .title-container {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-between;
        .heading-title {}
        .view-more {
            transition: 0.3s;
            display: inline-block;
            background-color: $color1;
            color: #fff;
            font-weight: 400;
            @include button-arrow;
            font-size: 14px;
            padding: 6px 18px;
            border-radius: 30px;
            &:hover {
                background-color: $color2;
            }
        }
        &.line-bottom {
            .heading-title {
                padding-bottom: 5px;
                position: relative;
                margin-bottom: 0;
                &::after {
                    background-color: $color1;
                    bottom: 0;
                    content: "";
                    height: 1px;
                    left: 0;
                    position: absolute;
                    width: 60%;
                }
            }
        }
    }
    .grid-products {
        margin-top: 30px;
        @include grid-gap2(5, 4, 2, 20, 15);
        article.item {
            & > a {}
            .cover {
                background-color: #fff;
                box-shadow: 0 0 6px 1px rgb(0 0 0 / 1%);
            }
            .cover-content {
                background-color: transparent;
                display: flex;
                flex-direction: column;
                align-items: center;
                h6 {
                    color: $color1;
                    margin-bottom: 0;
                    font-weight: 500;
                    padding-top: 15px;
                    text-align: center;
                    font-size: 16px;
                }
                .price-group {}
                .desc {
                    display: none;
                }
                .add-to-cart {
                    margin-bottom: 0;
                    margin-top: 15px;
                    padding: 0 10px;
                    height: 30px;
                    width: 100%;
                    border-radius: 30px;
                    background-color: $color1;
                    transition: 0.3s;
                    cursor: pointer;
                    &:hover {
                        background-color: $color2;
                    }
                }
            }
        }
    }
    &:not(.grid-products-0) {
        .title-container {
            border-top: 1px solid rgb(0 0 0 / 10%);
            padding-top: 30px;
        }
    }
    /*&:first-child {
        .title-container {
            border-top: none;
            padding-top: 0;
        }
    }*/
}

/** */
.section.videos-gallery {
    margin-top: 60px;
    @include mobile {
        margin-top: 30px;
    }
    .heading {
        justify-content: flex-end;
        margin-bottom: 20px;
        display: flex;
        .youtube-subcribe {
            display: inline-block;
            font-size: 13px;
            img {
                margin-left: 5px;
                display: inline-block;
            }
        }
    }
    .video-wrapper {
        align-items: flex-start;
        display: flex;
        @include grid-gap(15, 0);
        @include wp-mobile {
            flex-wrap: wrap;
        }
        .item {
            .cover {
                .cover-icon {
                    @include centering-elements;
                    &::before {
                        color: transparentize($color2, 0.3);
                        font-size: 24px;
                        font-weight: 300;
                        transition: .3s;
                    }
                }
            }
            &:hover {
                img {
                    filter: brightness(0.8);
                }
                .cover-icon {
                    &::before {
                        font-weight: 900;
                    }
                }
            }
        }
        .item-first {
            flex: 1 1 0;
            min-width: 0;
            @include wp-mobile {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .cover {
                .cover-icon {
                    @include centering-elements;
                    &::before {
                        font-size: 55px;
                    }
                }
            }
            .cover-content {
                display: none;
            }
        }
        .item-list {
            flex: 0 0 32%;
            max-width: 32%;
            overflow: hidden;
            ul {
                display: flex;
                flex-flow: row wrap;
                height: 0;
                list-style: none;
                margin: 0;
                overflow-x: hidden;
                overflow-y: auto;
                scroll-snap-type: y mandatory;
                transition: all .2s;
                align-items: flex-start;
                gap: 15px;
                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgb(255 255 255 / 0);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgb(241 241 241 / 0);
                }
                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(241 241 241 / 40%);
                    }
                }
                li {
                    display: flex;
                    flex: 0 0 100%;
                    max-width: 100%;
                    scroll-snap-align: start;
                    scroll-snap-stop: always;
                    transition: all .2s ease-in-out;
                    gap: 10px;
                    align-items: flex-start;
                    .cover {
                        flex: 0 0 33%;
                        max-width: 33%;
                    }
                    .cover-content {
                        flex: 1 1 0;
                        min-width: 0;
                        h6 {
                            font-weight: 500;
                            font-size: 15px;
                            margin-bottom: 5px;
                            @include not-desktop {
                                font-size: 14px;
                            }
                            a {
                                color: $medium-black;
                                @include line-clamp(2);
                            }
                        }
                        .view-more {
                            font-size: 13px;
                            font-weight: 300;
                            color: $color2;
                        }
                    }
                }
            }
            @include wp-mobile {
                flex: 0 0 100%;
                max-width: 100%;
                ul {
                    max-height: unset !important;
                    height: auto !important;
                    overflow-y: hidden;
                    scroll-snap-type: x mandatory;
                    overflow-x: auto;
                    gap: 10px;
                    flex-flow: row nowrap;
                    li {
                        flex: 0 0 calc(33% - 20px);
                        max-width: calc(33% - 20px);
                        margin-top: 0 !important;
                        flex-wrap: wrap;
                        @include mobile {
                            flex: 0 0 calc(50% - 20px);
                            max-width: calc(50% - 20px);
                        }
                        .cover, .cover-content {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/** */
.footer-widget {
    .site-logo {
        margin-bottom: 20px;
    }
    .site-name {
        font-size: 15px;
        font-weight: 500;
    }
    .site-description {
        color: $medium-black;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
    }
    .footer-content {
        ul {
            margin: 0;
            list-style: none;
            padding-left: 10px;
            li {
                a {
                    font-size: 15px;
                    transition: 0.3s;
                    color: $medium-black;
                    &:hover {
                        color: $color2;
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

/** */
.site-logo.footer-logo {
    a {
        display: block;
    }
    img {
        display: block;
        max-width: 220px;
        /*filter: brightness(0.3);*/
    }
}

/** */
.mega_menu > .mobile-menu.menu > li > a {
    @include desktop {
        padding: 15px 30px;
    }
}
.mobile-menu.menu .submenu > li > a {
    @include desktop {
        padding: 15px 30px;
    }
}

/** */
.list-post-cat-container {
    .filter-product-cat {
        .grid-productcat {
            @include grid-gap(20, 15);
            justify-content: center;
            .cell {
                @include grid-column2(6, 4, 2, 20, 15);
            }
        }
    }
}

/** */
.section.single-products {
    .products-details {
        box-shadow: 0 0 6px 1px rgb(0 0 0 / 2%);
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(6px);
        border-radius: 15px;
        @include grid-gap(30);
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
    .product-detail-inner {
        display: flex;
        @include grid-gap(30);
        border-bottom: 1px solid rgb(0 0 0 / 5%);
    }
    .product-infos {
        flex: 1 1 0;
        min-width: 0;
    }
    .product-title {
        margin-bottom: 10px;
    }
    .product-info-inner {
        display: flex;
        @include grid-gap(30);
        .product-images {
            flex: 0 0 45%;
            max-width: 45%;
            margin-bottom: 30px;
            .zoom-gallery {
                .product-image {}
                .product-gallery {
                    margin-top: 15px;
                    .cell {
                        @include grid-column2(5, 4, 3, 10);
                    }
                    .thumbs {
                        overflow: hidden;
                    }
                }
            }
        }
        .products-prices-inner {
            flex: 1 1 0;
            min-width: 0;
            margin-bottom: 30px;
            .in_stock {
                color: $color1;
                .available {
                    margin-bottom: 0;
                    font-weight: 500;
                    font-size: 15px;
                    span {
                        font-weight: 300;
                    }
                }
                .out-of-stock {
                    color: $color2;
                    margin-bottom: 0;
                }
                .stock-txt {
                    font-weight: 300;
                    color: $medium-black;
                    font-size: 14px;
                }
            }
        }
        .external-link {
            margin-top: 30px;
            box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
            .ext {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
                img {
                    flex: 0 0 auto;
                    display: block;
                    padding: 5px 10px;
                    max-height: 40px;
                    width: auto;
                    max-width: 100px;
                }
                a {
                    padding: 4px 10px;
                    background-color: #f7faff;
                    font-size: 14px;
                    font-weight: 300;
                    transition: 0.3s;
                    color: $medium-black;
                    &:hover {
                        color: #fff;
                        background-color: $color1;
                    }
                }
                &.shopee-link {
                    a {}
                    img {
                        background-color: #f53d2d;
                    }
                }
                &.lazada-link {
                    a {}
                    img {
                        background-color: #fff;
                    }
                }
                &.tiki-link {
                    a {}
                    img {
                        background-color: #1a94ff;
                    }
                }
            }
        }
    }
    .product-sku {
        margin-bottom: 5px;
        .sku {
            font-weight:500;
            span {
                font-weight: 300;
                font-size: 14px;
            }
        }
    }
    .products-prices {
        .price-group {
            padding-bottom: 20px;
            .variation-price {}
            .price-info {
                color: $medium-black;
                font-size: 15px;
                font-weight: 300;
            }
            .price {
                display: flex;
                position: relative;
                align-items: center;
                margin-top: 0;
            }
            .ribbon {
                position: absolute;
                top: -15px;
                right: 0;
                border-radius: 50%;
                background-color: transparentize($color-red, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                .rib {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 300;
                    padding: 5px;
                }
            }
            .price-amount {
                margin-top: 10px;
                display: block;
                color: $color-red;
                font-weight: 700;
                font-size: 20px;
                .price-currencySymbol {
                    color: currentColor;
                }
                &.price-km {
                    margin-left: 10px;
                    text-decoration: line-through;
                    font-weight: 400;
                    font-size: 15px;
                    color: $color1;
                }
            }
        }
        .btn_submit_addtocart {
            .number-addcart {
                display: flex;
                align-items: center;
                span {
                    font-size: 14px;
                    flex: 0 0 auto;
                    width: auto;
                    margin-right: 10px;
                    font-weight: 300;
                }
                input {
                    border: 1px solid #eee;
                    margin-bottom: 0;
                }
                .controls {
                    display: flex;
                    margin-bottom: 0;
                    button, input {
                        background: none;
                        border: 1px solid #e1e1e1;
                        color: #333;
                        height: 32px;
                        outline: none;
                        padding: 5px;
                        position: relative;
                        font-size: 14px;
                    }
                    button {
                        cursor: pointer;
                    }
                    input {
                        box-shadow: none;
                        font-weight: 500;
                        margin: 0 -1px;
                        text-align: center;
                        width: 50px;
                    }
                }
            }
            .add_to_cart_button {
                max-width: 470px;
                width: 100%;
                background-color: $color1;
                color: #fff;
                margin-bottom: 0;
                margin-top: 20px;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 16px;
                transition: 0.3s;
                &:hover {
                    background-color: $color2;
                }
                a {
                    display: block;
                    font-weight: 700;
                    color: #fff;
                    span {
                        font-weight: 300;
                        margin-right: 5px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    .product-addon {
        flex: 0 0 26%;
        max-width: 26%;
        min-width: 250px;
        position: relative;
        padding-left: 10px;
        margin-bottom: 30px;
        &::before {
            content: "";
            width: 1px;
            height: 100%;
            background-color: rgb(0 0 0 / 5%);
            left: -10px;
            position: absolute;
        }
        .info-list {
            margin: 0;
            list-style: none;
            font-size: 15px;
            li {
                border-bottom: 1px solid rgb(0 0 0 / 5%);
                display: flex;
                align-items: baseline;
                padding-top: 12px;
                padding-bottom: 12px;
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                &.excerpt {
                    display: block;
                    padding-top: 0;
                    padding-bottom: 20px;
                    color: $medium-black;
                    line-height: 1.6;
                    p {
                        line-height: 1.6;
                    }
                }
                & > span {
                    font-weight: 300;
                    white-space: nowrap;
                    flex: 0 0 auto;
                    width: auto;
                    & + span {
                        font-weight: 400;
                        padding-left: 15px;
                        white-space: normal;
                        flex: 1 1 0;
                        min-width: 0;
                        max-width: 100%;
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .products-content {
        .inner-title {
            margin-bottom: 0;
            span {
                color: $color1;
                font-weight: 700;
                position: relative;
                padding-bottom: 5px;
                &::after {
                    background-color: $color1;
                    bottom: 0;
                    content: "";
                    height: 1px;
                    left: 0;
                    position: absolute;
                    width: 60%;
                }
            }
        }
        .entry-post-wrapper {
            margin-top: 30px;
        }
    }
}
.section.archive-products {
    .inside-search .frm-search {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        input[type="search"] {
            border: 1px solid rgb(0 0 0 / 10%);
            height: 42px;
        }
    }
    .grid-products-wrapper {
        margin-top: 0;
        @include mobile {
            margin-top: 0;
        }
    }
}

/** */
.products-properties {
    margin-top: 30px;
    .frm-properties-search {
        select {
            color: $medium-black;
            height: 40px;
            line-height: normal;
            padding-left: 15px;
            background-color: rgb(255 255 255 / 90%);
            backdrop-filter: blur(15px);
            border-radius: 15px;
            font-size: 15px;
            option, optgroup {
                color: $color1;
            }
        }

        .ms-options-wrap {
            margin-bottom: 15px;
            & > button {
                color: $medium-black;
                height: 40px;
                line-height: normal;
                padding-left: 15px;
                background-color: rgb(255 255 255 / 90%);
                backdrop-filter: blur(15px);
                font-size: 16px;
                span {
                    color: $color1;
                }
            }
            .ms-options {
                ul {
                    li {
                        label {
                            color: $medium-black;
                            display: flex;
                            align-items: center;
                            padding-left: 30px;
                            padding-top: 0;
                            padding-bottom: 0;
                            font-size: 15px;
                            input[type="checkbox"] {
                                top: 5px;
                                left: 10px;
                            }
                        }
                        &.optgroup {
                            & > .label {
                                background: none;
                                padding: 10px 5px;
                                color: $color1;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
        button[type="submit"] {
            position: relative;
            border-radius: 0;
            transition: 0.5s ease;
            padding: 8px 16px;
            background-color: transparentize($color1, 0.95);
            font-weight: 400;
            color: #ddd;
            font-size: 14px;
            line-height: normal;
            width: 100%;
            text-transform: uppercase;
            border: 1px solid $color1;
            @include _effect2;
            display: none;
            span {
                color: currentColor;
            }
        }
    }
}

/** */
.thumbs .mz-thumb {
    img {
        box-shadow: none;
    }
    &:hover, &.mz-thumb-selected {
        img {
            filter: brightness(80%);
        }
    }
}
.bct {
    margin-top: 10px;
    a {
        display: inline-block;
    }
    img {
        width: 150px;
        max-width: 100%;
    }
}

/** */
.section.section-partners.section-partners-3008 {
    .partners-wrapper {
        .partner-item {
            @include grid-column2(5, 4, 3, 0, 0);
            padding: 15px;
        }
    }
}

/** */
.product-cat-desc {
    margin-top: 40px;
    margin-bottom: 40px;
    .product-cat-desc-inner {
        /*max-height: 800px;*/
        overflow: hidden;
        position: relative;
        background-color: rgb(255 255 255 / 90%);
        backdrop-filter: blur(8px);
        padding: 30px;
        box-shadow: 0 0 6px 1px rgb(0 0 0 / 2%);
        border-radius: 15px;
        display: block !important;
        @include mobile {
            padding: 15px;
        }
        .viewmore-wrapper {
            text-align: center;
            cursor: pointer;
            position: absolute;
            z-index: 9999;
            bottom: 0;
            width: 100%;
            background: #fff;
            left: 0;
            padding-bottom: 20px;
            padding-top: 0;
            &::before {
                height: 250px;
                content: "";
                will-change: background-color;
                background-image: linear-gradient(to top, #fff 0%, rgb(255 255 255 / 30%) 100%);
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 9;
            }
            .btn-viewmore {
                font-size: 14px;
                font-weight: 300;
                margin: 0;
                padding: 8px 24px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                display: inline-block;
                border-radius: 30px;
                background-color: transparentize($color1, 0.3);
                backdrop-filter: blur(8px);
                box-shadow: 6px 3px 18px transparentize($color1, 0.8);
                color: #fff;
                z-index: 10;
                &::after {
                    margin-left: 10px;
                }
            }
        }
    }
}

/** */
.fancybox__viewport  {
    #desc-inner {
        max-height: unset !important;
        .viewmore-wrapper {
            display: none !important;
        }
    }
}

/** */
.section.section-partners {
    margin-bottom: 40px;
    .swiper-controls .swiper-pagination {
        bottom: -30px;
    }
    .swiper-slide .item {
        padding: 15px;
        height: auto;
        @include mobile {
            padding: 10px;
        }
    }
}

/** */
.product-properties {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .prop {
        & > label {
            font-weight: 300;
            margin-bottom: 5px;
            color: $color1;
        }
        & > select {
            border: 1px solid rgba(0, 0, 0, 0.05);
            height: 42px;
            margin-bottom: 0;
            font-size: 15px;
            padding-left: 15px;
            width: auto;
            max-width: 100%;
            padding-right: 40px;
            background-position: right -30px center;
        }
    }
}

/** */
.rating--inner {
    display: flex;
    align-items: center;
    &.selected {
        margin-top: 5px;
        margin-bottom: 15px;
        position: relative;
        ul li.active i {font-weight: 700;}
        .votes {font-size: 12px;}
        .ul-rating {position: relative;margin-right: 15px;}
        ul li i {
            font-size: 12px;
            font-weight: 400;
        }
        .ul-rating ul {
            margin-right: 0;
            flex-direction: row;
            &.rated {
                position: absolute;
                left: 0;
                overflow: hidden;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                li i {font-weight: 700;}
            }
        }
        ul li:last-child {padding-right: 0}
    }
    &:not(.selected) ul:hover li i {font-weight: 400;}
    &:not(.selected) ul:hover li:hover i {font-weight: 700 !important;}
    &:not(.selected) ul li:hover i {font-weight: 700;}
    &:not(.selected) ul li:hover ~  li i {font-weight: 700;}
    &:not(.selected) ul:hover li:hover ~  li i {font-weight: 700;}
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0 10px 0 0;
        flex-direction: row-reverse;
        position: relative;
        li {
            padding-right: 5px;
            display: flex;
            align-items: center;
            i {
                color: #e74929;
                font-weight: 400;
                font-size: 18px;
            }
            &.active ~  li i {font-weight: 700;}
            &.active i {font-weight: 700;}
        }
    }
    &:not(.selected) ul:hover li.active ~  li i {
        /*font-weight: 400;*/
    }
    .votes {font-size: 15px;}
}

/** */
.block-comments-wrapper {
    .rating-system {
        margin-top: 20px;
    }
    .comments-area {
        width: 620px;
        max-width: 100%;
    }
}

/** */
.zalo-chat-widget {
    position: fixed;
    background-color: transparent;
    cursor: pointer;
    z-index: 9998;
    width: 63px;
    height: 63px;
    right: 24px;
    bottom: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        display: block;
    }
    img {
        display: block;
    }
}
.messenger-chat-widget {
    position: fixed;
    background-color: transparent;
    cursor: pointer;
    right: 31px;
    bottom: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    a {
        display: block;
        opacity: .8;
        width: auto;
        height: auto;
        svg {
            width: 48px;
            height: 48px;
            display: block;
        }
    }
}

/** */
.branch-wrapper {
    @include grid-gap2(2, 2, 1, 40, 30);
    width: 100%;
    .branch-cell {
        .branch-title {}
        .excerpt {}
        .branch-inner {
            .address {
                font-style: italic;
                margin-bottom: 10px;
            }
            .meta-inner {
                display: flex;
                align-items: baseline;
                label {
                    margin-right: 10px;
                    font-weight: 300;
                }
                &.map-inner {
                    margin-top: 20px;
                    .iframe-inner {
                        @include responsive-embed;
                        @include responsive-ratio(16, 9);
                        @include mobile {
                            @include responsive-ratio(4, 3);
                        }
                    }
                }
            }
        }
    }
}

/** */
.added_to_cart {
    color: $color-red;
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
    &::before {
        color: $color-red;
        margin-right: 5px;
        font-weight: 900;
    }
    .view-cart-link {
        margin-left: 10px;
        &::before {
            margin-right: 5px;
            font-weight: 900;
        }
    }
}

/** */
.section-cart {
    .no-cart {}
    .page-content {
        width: 100%;
    }
    .single-title {
        margin-bottom: 20px;
    }
    .list-cart {
        gap: 30px;
        display: flex;
        flex-direction: column;
        .details-giohang-tbl {
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px);
            padding: 20px;
            box-shadow: 0 0 12px rgb(0 0 0 / 5%);
            table.list-cart-tbl {
                margin-bottom: 0;
                position: relative;
                z-index: 0;
                tr {
                    td, th {
                        text-align: left;
                        font-weight: 400;
                        padding: 10px 15px;
                        &.text-center {
                            text-align: center;
                        }
                    }
                    th {
                        font-size: 15px;
                        font-weight: 300;
                        &.sl {
                            width: 100px;
                        }
                        &.thumb {
                            width: 110px;
                        }
                        &.btn {
                            width: 60px;
                        }
                    }
                    a.thumbs {
                        display: block;
                        img {
                            width: 80px;
                            height: 60px;
                            object-fit: cover;
                            object-position: center;
                            display: block;
                        }
                    }
                    .title_sp {
                        font-weight: 500;
                    }
                }
                .actions {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    align-items: center;
                    .button {
                        line-height: normal;
                        padding: 8px 10px;
                        background-color: transparentize($color1, 0.1);
                        font-weight: 300;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .subtotal-giohang-tbl {
            margin-right: 0;
            margin-left: auto;
            box-shadow: 0 0 12px rgb(0 0 0 / 5%);
            .cart_totals {
                border-radius: 5px;
                background-color: rgba(255, 255, 255, 0.95);
                backdrop-filter: blur(10px);
                padding: 20px;
                width: 500px;
                max-width: 100%;
                tr {
                    th {
                        text-align: left;
                        font-weight: 400;
                    }
                }
            }
        }
        .qty_sp {
            padding: 5px 10px;
            height: unset;
            line-height: normal;
            box-shadow: none;
            border: 1px solid rgb(0 0 0 / 8%);
            margin-bottom: 0;
        }
        .delete_cart {
            &::before {
                font-weight: 400;
                color: $color-red;
            }
        }
    }
    .proceed-to-checkout {
        a {
            /*@include _effect2;*/
            background-color: $color1;
            display: block;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 0;
            text-align: center;
            &:hover {
                background-color: $color2;
            }
        }
    }
}

/** */
.price_sp {
    .price-amount {
        color: $color-red;
        font-weight: 500;
        font-size: 14px;
        .price-currencySymbol {
            font-weight: 400;
        }
        &.price-promotion {
            color: $medium-black;
            font-weight: 300;
            font-size: 14px;
            text-decoration: line-through;
            .price-currencySymbol {
                font-weight: 300;
            }
        }
    }
}

/** */
.ajax-loading {
    position: relative;
    &::before {
        @include absolute-full;
        z-index: 9998;
        content: '';
        background-color: rgb(0 0 0 / 10%);
    }
    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        z-index: 9999;
        border-left: 3px solid #f3f3f3;
        border-right: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid $medium-black;
        border-bottom: 3px solid $medium-black;
        width: 30px;
        height: 30px;
        animation: spin 2s linear infinite;
    }
}

/** */
.section-checkout {
    .no-cart {}
    .single-title {
        margin-bottom: 20px;
    }
    .checkout-heading {
        text-transform: uppercase;
        font-weight: 400;
        position: relative;
        color: $color1;
        padding-bottom: 10px;
        &::before {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: transparentize($color1, 0.7);
        }
    }
    .proceed-to-checkout {
        margin-top: 30px;
    }
    .list-checkout {
        gap: 20px;
        display: flex;
        flex-flow: row wrap;
        .customer_details {
            box-shadow: 0 0 12px rgb(0 0 0 / 5%);
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px);
            padding: 20px;
            flex: 0 0 60%;
            max-width: 60%;
            .form-group {
                label {
                    margin-bottom: 5px;
                    font-weight: 500;
                    font-size: 15px;
                }
                input, select {
                    border: 1px solid rgb(0 0 0 / 20%);
                    height: 40px;
                    line-height: normal;
                    font-size: 15px;
                }
                input {
                    padding: 0 10px;
                }

                select {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 10px;
                }
                textarea {
                    border: 1px solid rgb(0 0 0 / 20%);
                    padding: 10px;
                    line-height: normal;
                    font-size: 15px;
                }
                button[type="submit"], button[type="button"] {}
            }
        }
        .order_review {
            box-shadow: 0 0 12px rgb(0 0 0 / 5%);
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px);
            padding: 20px;
            flex: 1 1 0;
            min-width: 0;
            .list-cart-tbl {
                font-size: 15px;
                .thumbs {
                    display: block;
                    img {
                        width: 60px;
                        display: block;
                    }
                }
            }
        }
        .checkout-button {
            @include _effect2;
            background-color: $color1;
            display: block;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            width: 100%;
            i {
                margin-left: 5px;
            }
        }
        .checkout-payment {
            margin-top: 30px;
        }
        .payment_methods {
            margin: 0;
            list-style: none;
            li {
                margin-bottom: 10px;
                label {
                    font-weight: 500;
                    font-size: 16px;
                }
                input {
                    margin-bottom: 0;
                }
                .payment_box {
                    font-size: 14px;
                    border: 1px dashed $color-red;
                    padding: 15px;
                    margin-top: 10px;
                    background-color: #fcfcfc;
                }
            }
        }
        .totals-tbl {
            tbody tr:nth-child(even) {
                background-color: #fff;
            }
        }
    }
}

/** */
.thankyou-order-details {
    list-style: none;
    margin: 0 0 30px;
    li {
        margin-bottom: 5px;
        display: flex;
        align-items: baseline;
        span {
            font-weight: 300;
            margin-right: 10px;
        }
        strong {}
        &.order-overview__payment-method {}
    }
}

/** */
.att-checkbox-group {
    .checkbox-inner {
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        .item {
            position: relative;
            margin-bottom: 0;
            text-align: left;
            border: 1px solid rgb(0 0 0 / 10%);
            padding: 5px 10px;
            border-radius: 5px;
            background-color: rgb(0 0 0 / 1%);
            input {
                & + label {
                    transition: .28s ease;
                    user-select: none;
                    margin-left: 0;
                    margin-right: 0;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-weight: 300;
                    color: $medium-black;
                    font-size: 13px;
                    opacity: 0.8;
                    .before {
                        @include box(28);
                        border-radius: 50%;
                        box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
                        &::before {
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                    .after {
                        opacity: 0;
                        transition: 0.3s;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: transparentize($color2, 0.3);
                        backdrop-filter: blur(5px);
                        color: #fff;
                        line-height: normal;
                        padding: 0;
                        width: 20px;
                        height: 20px;
                        @include flex-center;
                        border-radius: 50% 0 0 0;
                    }
                }
                &:not(:checked), &:checked {
                    position: absolute;
                    left: -9999px;
                    visibility: hidden;
                }
                &:checked {
                    & + label {
                        .after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

/** */
.cart-atts {
    font-size: 14px;
    color: $medium-black;
    margin-top: 5px;
    &.checkout-atts {
        font-size: 13px;
    }
    .atts-item {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        gap: 5px;
        .att-label {
            font-weight:300
        }
        .att-value {
            font-weight: 500;
        }
    }
}